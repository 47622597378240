import React, { useEffect, useContext } from "react";
import {
  GlobalStateContext,
  GlobalStateInterface,
  GlobalDispatchContext,
  FIRE_CRACKING_SOUND_ACTIONS,
} from "@context/GlobalContextProvider";
import { Howl } from "howler";
import { Layout } from "@components/Layout";
import {
  ButtonCreateYourLantern,
  ButtonAnchor,
} from "@components/Button/types";
import { Title } from "@components/Title";
import { HeadingVariant } from "@components/Title/types";
import pronunciation from "@assets/audio/pronunciation.mp3";
import fireCracking from "@assets/audio/fire_video_background.mp3";

export default ({ location }) => {
  const state: GlobalStateInterface = useContext(GlobalStateContext);
  const dispatch: Dispatch<any> = useContext(GlobalDispatchContext);

  const fireCrackingSound = new Howl({
    src: fireCracking,
    loop: true,
  });

  const pronunciationSound = new Howl({
    src: pronunciation,
  });

  const stopFireCrackingSoundEffect = () => {
    if (state.fireCrackingSound) {
      state.fireCrackingSound.stop();
    } else {
      fireCrackingSound.stop();
    }
  };

  useEffect(() => {
    if (
      !state.backgroundTrack ||
      (state.isBGTrackCurrentlyPlaying && !state.fireCrackingSound)
    ) {
      fireCrackingSound.play();
      fireCrackingSound.volume(0.3);
      dispatch({
        type: FIRE_CRACKING_SOUND_ACTIONS.STARTED_PLAYING,
        payload: {
          play: () => fireCrackingSound.play(),
          stop: () => fireCrackingSound.stop(),
        },
      });
    } else if (state.fireCrackingSound) {
      state.isBGTrackCurrentlyPlaying
        ? state.fireCrackingSound.play()
        : state.fireCrackingSound.stop();
    }

    return () => {
      stopFireCrackingSoundEffect();
    };
  }, [state]);

  const headings = [
    {
      value: "CELEBRATE",
      priority: 2,
      type: HeadingVariant.Bold,
    },
    {
      value: "The Lantern Festival",
      priority: 1,
    },
  ];

  return (
    <Layout
      className="aboutPage"
      currentPath={location.pathname}
      videoBackground={require("@assets/videos/lantern.mp4")}
      withGoBackCross
      pageTitle="Learn more about The Lantern Festival | EMBRACE PS"
      onClick={() => stopFireCrackingSound()}
    >
      <Title headings={headings} />
      <div className="aboutFestivalDescription">
        <aside
          className="playPronunciation"
          onClick={() => pronunciationSound.play()}
        >
          元宵节 ( <img src={require("@assets/icons/audio_icon.svg")} />{" "}
          “Yuánxiāo jié”
        </aside>
        ) or Lantern Festival, is celebrated on the 15th day of Lunar New Year
        in countries across Asia.
        <br />
        <br />
        For more than 2000 years, people have come together to release paper
        lanterns featuring messages of good fortune, family reunions, an
        abundant harvest, prosperity and love.
        <br />
        <br />
        Send your wishes skyward to join others from all around the world.
      </div>
      <section className="exploreButtonsWrap startPage">
        <ButtonCreateYourLantern />
        <ButtonAnchor href="/lanterns">Explore virtual lanterns</ButtonAnchor>
      </section>
    </Layout>
  );
};
